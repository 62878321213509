.App {
  text-align: center;
}

.main-page {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
}

.top-section {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: rgb(236, 236, 236);
}

.bottom-section {
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
}

.bottom-section button {
  height: 20%;
  width: 20%;
  font-size: 30px;
}


