.bodyContent{
    margin-top:3%;
    padding: 24px 24px;
    background-color:#b2cacf14;
}

.header .MuiAppBar-colorPrimary{
    background-color: #fff;
    color: #535359;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
}

.drawerRight{
    padding: 24px 24px;
    width: 346px;
    .filterHeading{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #535359;
        margin-bottom: 41px;
    }
}


#navBarMenus #appbar-collapse {
        margin-left: 90px;
    }
#navBarMenus #appbar-collapse a{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #535359;
    margin-right: 30px;
    text-decoration: none;
    
}
#navBarMenus #appbar-collapse a[aria-current='page']{
        color: #e91e63;
}
header .profileMenuBtn{
    width: 100%;
    text-align: right;
}

.profileIcons{
    min-width: 32px !important;
}

.backDropLoader{
    z-index: 1111 !important;
}

.navLinkBar{
    display: contents;
    text-decoration: none;
    color: unset;
}

.loginErrorMsg{
    text-align: center;
    color: #e91e63;
}


#statusConfigurationPage {
    #uploadBtn{
        margin-top: 4%;
        text-align: center;
        border: 1px dashed #000;
        padding: 24px
    }
}
