@import url('https://fonts.googleapis.com/css?family=Raleway');

/*** Login Form Start ***/
.login_container_block{
    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        background-image: url('../images/login_bg.png');
      }
    .box{
        background: #FFFFFF;
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.082);
        border-radius: 11px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .box-header{
        padding: 43px 12px;
        .logo{
            text-align: center;
            img{
                width: 246px;
            }
        }
        h1{
            text-align: center;
        }
    }
    .box-form{
        padding: 15px 32px;
        .material_input{
            margin-bottom: 20px;
            & label.Mui-focused {
                color: #07a8bd;
            }
            & .MuiInput-underline:after {
                border-bottom: #07a8bd;
            }
            & .MuiOutlinedInput-root {
                // & fieldset {
                //   border-color: red;
                // }
                &:hover fieldset {
                  border-color: #07a8bd;
                }
                &.Mui-focused fieldset {
                  border-color: #07a8bd;
                }
              }
        }
        
        .remember_forgot{
            display: flex;
            .forgot_password{
                align-self: center;
                width: 66%;
                text-align: right;
            }
        }
        .formBtn{
            text-align: center;
            padding: 17px 51px;
            .submitBtn{
                border: 1px solid #07a8bd;
                color: #07a8bd;
                padding: 15px 16px;
                &:hover{
                    background: #07a8bd;
                    color: #fff;
                }
                &:active{
                    background: #07a8bd;
                    color: #fff;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .login_container_block {
        .box{
            left: 48%;
            width: 92%;
        }
    }
}

/*** Login Form End ***/


