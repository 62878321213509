#profilePage{
    #profilePageContent{
        margin-top: 5%;
        .leftSide{
            padding: 20px;
            background: #fff;
            .profileInfoHead{
                font-size: 21px;
                font-family: inherit;
                margin-bottom: 10%;
                font-weight: 700;
                color: #525252cf;
                text-align: center;
            }
            .profileImg{
                text-align: center;
                img{
                    border: 4px solid #e91e63;
                    border-radius: 50%;
                    margin-bottom: 5%;
                }
            }
            .profileDetails{
                margin-top: 5%;
                text-align: center;
                font-size: 18px;
                color: #666666e3;
            }
        }
        .rightSide{
            padding: 20px;
            background: #fff;
            .profileInfoHead{
                font-size: 21px;
                font-family: inherit;
                font-weight: 700;
                color: #525252cf;
            }
            .profileTabs{
                .MuiAppBar-colorPrimary{
                    background:#e91e63;
                }
                .MuiTab-root{
                    font-weight: 600;
                }
                .MuiTab-textColorInherit.Mui-selected{
                    background: #fff;
                    color: #e91e63;
                }
                .personalInfoDet{
                    margin-top: 2%;
                    .profileDetHead{
                        font-size: 18px;
                        font-weight: 700;
                        margin-bottom: 10%;
                    }
                    .profileDetText{
                        margin-top: 1%;
                        color: #727272;
                        font-size: 18px;
                        margin-bottom: 10%;
                    }
                }
                .passwordSection{
                    margin-top: 2%;
                    .profileDetHead{
                        font-size: 18px;
                        font-weight: 700;
                        margin-bottom: 13%;
                    }
                    .profileDetText{
                        margin-top: 1%;
                        color: #727272;
                        font-size: 18px;
                        margin-bottom: 10%;
                    }
                }
            }
        }
    }
}