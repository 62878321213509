#dashboardPage{
    .countSection{
        margin-top: 3%;
        .countBlocks{
            background: #fff;
            padding: 20px;
            display: grid;
            place-items: center;
            box-shadow: 0px 6px 18px #c7c7c77a;
            span{
                margin: 3% auto;
            }
        }
    }
}